import chiSiamoImg from "./../chi_siamo_img.png";

interface CopySection {
  title: string;
  content: JSX.Element;
}

export const historySection: CopySection = {
  title: "Chi siamo",
  content: (
    <>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <p style={{ flex: 2 }}>
          ELECONTROL nasce le 2022 da uno spin-off del reparto “automazione” di{" "}
          <b>STAFF S.r.l.</b> dalla quale ne eredita le competenze e conoscenze,
          maturate in più di <b>30 anni di attività</b> nel settore specifico
          della Building e Industrial Automation.
          <br />
          <br />
          Ci rivolgiamo principalmente a 2 mercati specifici: <br />
          <li>
            <b>Settore Industriale</b>: sistemi di automazione e controllo per
            macchine, linee di produzione e Siti industriali
          </li>
          <li>
            <b>Settore Civile-Terziario</b>: Automazione e gestione funzionale
            ed energetica degli edifici, quali strutture ricettive, ospedaliere,
            siti produttivi ed amministrativi, edifici storici e residenziali di
            pregio.
          </li>
        </p>
        <div
          style={{
            flex: 1,
            padding: "5rem",
            textAlign: 'center',
          }}
        >
          <img
            style={{
              width: "400px",
              height: "400px",
            }}
            src={chiSiamoImg}
            alt="chi_siamo"
          />
        </div>
      </div>
      <p>
        <b>PRINCIPALI ATTIVITÀ</b>
        <br />
        <ul>
          <li>
            <span className="blue_text">Progettazione</span> dei Sistemi di
            Comando e Controllo;
          </li>
          <li>
            <span className="blue_text">Produzione Quadri Elettrici</span>
            (Apparecchiature Elettriche Assiemate) per Distribuzione e
            Automazione nei settori Terziario, Industriale e Pubblico
          </li>
          <li>
            <span className="blue_text">Programmazione</span> e
            ingegnerizzazione di sistemi di Controllo e Automazione
          </li>
          <li>
            <span className="blue_text">Installazione</span> dedicata in
            prevalenza al settore “Cablaggio Bordo Macchina”
          </li>
          <li>
            <span className="blue_text">Vendita</span> prodotti specifici per i
            settori di riferimento con Partnership diretta con area{" "}
            <b>BMS Honeywell</b>
          </li>
          <li>
            <span className="blue_text">Assistenza</span> Tecnica alla Clientela
          </li>
          <li>
            <span className="blue_text">Sviluppo</span> di nuove applicazioni in
            partnership con clienti e collaboratori
          </li>
        </ul>
      </p>
    </>
  ),
};

export const collaborateSection: CopySection = {
  title: "Collabora",
  content: (
    <p>
      La nostra Azienda è fatta e gestita da <strong>Persone</strong>.
      <br />
      Sono i nostri collaboratori, con le loro competenze, il loro senso di
      responsabilità, la loro dedizione che determinano e determineranno il
      nostro successo.
      <br />
      Entrare nel Team <strong>ELECONTROL</strong> significa far parte di una
      squadra <b>giovane</b> e <b>affiatata</b>, pronta ad accompagnarti e
      supportarti nella tua <b>crescita professionale</b>.
      <br />
      Il nostro obiettivo è quello di rendere i nostri Collaboratori
      responsabili, professionali, appassionati e valorizzati.
      <br />
      <br />
      Se sei interessato, compila il form sottostante ed inviaci la Tua
      richiesta:
    </p>
  ),
};

export const workWithUsSection: CopySection = {
  title: "Lavora con noi",
  content: (
    <p>
      La nostra Azienda è fatta e gestita da <strong>Persone</strong>.
      <br />
      Sono i nostri collaboratori, con le loro competenze, il loro senso di
      responsabilità, la loro dedizione che determinano e determineranno il
      nostro successo.
      <br />
      Entrare nel Team <strong>ELECONTROL</strong> significa far parte di una
      squadra <b>giovane</b> e <b>affiatata</b>, pronta ad accompagnarti e
      supportarti nella tua <b>crescita professionale</b>.
      <br />
      Il nostro obiettivo è quello di rendere i nostri Collaboratori
      responsabili, professionali, appassionati e valorizzati.
      <br />
      <br />
      Se sei interessato, compila il form ed inviaci la Tua richiesta:
    </p>
  ),
};

export const locationSection: CopySection = {
  title: "Dove siamo",
  content: <></>,
};

export const footerSocials = "rimani in contatto:";
export const footerContacts = "Contattaci";
export const footerLocation = "Dove siamo";

export const workInProgress =
  "👷‍♀️ sito in costruzione | visitaci periodicamente per non perdere le novità! 👷";
