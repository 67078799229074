import React from "react";
import logo from "./elecontrol_logo.png";

import "./App.css";
import Button from "./components/service-card";
import {
  // collaborateSection,
  footerContacts,
  footerLocation,
  footerSocials,
  historySection,
  locationSection,
  workInProgress,
  workWithUsSection,
} from "./copy/it";

function App() {
  // const servicesRef = React.useRef<HTMLDivElement>(null);
  const historyRef = React.useRef<HTMLDivElement>(null);
  const workWwithUsRef = React.useRef<HTMLDivElement>(null);
  const collaborateWwithUsRef = React.useRef<HTMLDivElement>(null);
  const doveSiamoRef = React.useRef<HTMLDivElement>(null);

  const scrollToElement = (
    elementRef: React.RefObject<HTMLDivElement>
  ): void => {
    window.scrollTo({
      top: elementRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        <div className="home_section">
          <div className="app_logo_container">
            <img src={logo} className="App-logo" alt="logo" />
          </div>

          <div className="home_section_content">
            <div className="link_container">
              {/*
              <Button
                title="Servizi"
                theme="light"
                onClick={() => scrollToElement(servicesRef)}
              />
              */}
              <Button
                title="Chi siamo"
                theme="light"
                onClick={() => scrollToElement(historyRef)}
              />
              <Button
                title="Collabora"
                theme="light"
                onClick={() => scrollToElement(collaborateWwithUsRef)}
              />
              <Button
                title="Lavora con noi"
                theme="light"
                onClick={() => scrollToElement(workWwithUsRef)}
              />
              <Button
                title="Dove siamo"
                theme="light"
                onClick={() => scrollToElement(doveSiamoRef)}
              />
            </div>
          </div>
        </div>

        {/* SERVICES */}
        {/* <div
          ref={servicesRef}
          className="services_section section_flex_orientation"
        >
          <div className="vertical_text section_title_dark">Servizi</div>
          <div className="services_content">asdojaosjdiajsd asd asdaosidj</div>
        </div>*/}

        {/* WORK-IN-PROGRESS */}
        <div className="footer_work_in_progress">
          <span>{workInProgress}</span>
        </div>

        {/* HISTORY */}
        <div
          ref={historyRef}
          className="history_section  section_flex_orientation"
        >
          <div className="vertical_text section_title_dark">
            {historySection.title}
          </div>
          <div className="history_content">{historySection.content}</div>
        </div>

        {/* COLLABORATE WITH US */}
        {/*<div
          ref={collaborateWwithUsRef}
          className="collaborate_with_us_section  section_flex_orientation"
        >
          <div className="vertical_text section_title_dark">
            {collaborateSection.title}
          </div>
          <div className="collaborate_with_us_content">
            {collaborateSection.content}
            <div
              style={{
                paddingTop: "2rem",
                display: "flex",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Button
                title="collabora con noi"
                icon
                theme="dark"
                fixedSize={25}
                onClick={() =>
                  (window.location.href =
                    "https://forms.office.com/Pages/ResponsePage.aspx?id=OsOGOS_78UO4dtHlD7VXa8Cu3ZVF1sVLo3MUDPUg8ClURVhYNUVGWEk3Qlg1NzZGT1dJV0QzMTJaTS4u")
                }
              />
            </div>
          </div>
        </div>*/}

        {/* WORK WITH US */}
        <div
          ref={workWwithUsRef}
          className="work_with_us_section  section_flex_orientation"
        >
          <div className="vertical_text section_title_light">
            {workWithUsSection.title}
          </div>
          <div className="work_with_us_content">
            {workWithUsSection.content}
            <div
              style={{
                paddingTop: "2rem",
                display: "flex",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Button
                title="lavora con noi"
                theme="light"
                fixedSize={25}
                icon
                onClick={() =>
                  (window.location.href =
                    "https://forms.office.com/Pages/ResponsePage.aspx?id=OsOGOS_78UO4dtHlD7VXa8Cu3ZVF1sVLo3MUDPUg8ClURVhYNUVGWEk3Qlg1NzZGT1dJV0QzMTJaTS4u")
                }
              />
            </div>
            <p
              style={{
                marginTop: "5rem",
              }}
            >
              Leggi il nostro
              <a
                style={{
                  color: "#037fa3",
                  fontWeight: "700",
                  textDecoration: "none",
                  marginLeft: "0.5rem",
                }}
                rel="noreferrer"
                target="_blank"
                href="documents/codice_etico_ELECONTROL.pdf"
              >
                Codice etico
              </a>
            </p>
          </div>
        </div>

        {/* DOVE SIAMO */}
        <div
          ref={doveSiamoRef}
          className="services_section section_flex_orientation"
        >
          <div className="vertical_text section_title_dark">
            {locationSection.title}
          </div>
          <div className="services_content">
            <iframe
              title="mappa"
              src="https://www.google.com/maps/d/u/0/embed?mid=1WayQf-I2eVEYG8_3TfbugvQgTOz8AgM&ehbc=2E312F"
              style={{ width: "100%", height: "100%", border: "0px" }}
            />
          </div>
        </div>

        {/* FOOTER */}
        <div className="footer_header">
          <span>{footerSocials}</span>
          <div className="footer_header_icons_container">
            <a
              rel="noreferrer"
              target="_blank"
              href="mailto:amministrazione@elecontrol.it"
            >
              <img
                className="footer_icon"
                alt="email"
                src="https://img.icons8.com/material-outlined/48/000000/new-post.png"
              />
            </a>
            {/*<a rel="noreferrer" target="_blank" href="https://www.google.com">
              <img
                className="footer_icon"
                alt="facebook"
                src="https://img.icons8.com/material-outlined/48/000000/facebook-new.png"
              />
              </a>*/}
            {/*<a rel="noreferrer" target="_blank" href="https://www.google.com">
              <img
                className="footer_icon"
                alt="instagram"
                src="https://img.icons8.com/material-outlined/48/000000/instagram-new--v1.png"
              />
            </a>*/}
            <a rel="noreferrer" target="_blank" href="https://www.google.com">
              <img
                className="footer_icon"
                alt="linkedin"
                src="https://img.icons8.com/material-outlined/48/000000/linkedin.png"
              />
            </a>
            {/*<a rel="noreferrer" target="_blank" href="https://www.google.com">
              <img
                className="footer_icon"
                alt="whatsapp"
                src="https://img.icons8.com/material-outlined/48/000000/whatsapp--v1.png"
              />
            </a>*/}
          </div>
        </div>

        <div className="footer">
          <div className="footer-logo-container">
            <img src={logo} className="footer-logo" alt="logo" />
          </div>

          <div className="footer_content">
            <p className="footer_title">{footerContacts}</p>
            <div className="footer_row">
              <span className="material-symbols-outlined btn_icon">mail</span>
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  marginLeft: "0.5rem",
                }}
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=OsOGOS_78UO4dtHlD7VXa8Cu3ZVF1sVLo3MUDPUg8ClUMklMWFVYMEFLTzhENExMRUdPT1g1Q1FWUS4u"
              >
                Inviaci la tua richiesta
              </a>
            </div>
            <div className="footer_row">
              <span className="material-symbols-outlined btn_icon">phone</span>
              <span
                style={{
                  color: "white",
                  textDecoration: "none",
                  marginLeft: "0.5rem",
                }}
              >
                0173 240269
              </span>
            </div>
            <div className="footer_row">
              <span>P.IVA</span>
              <span
                style={{
                  color: "white",
                  textDecoration: "none",
                  marginLeft: "0.5rem",
                }}
              >
                02701280063
              </span>
            </div>
          </div>

          <div className="footer_content">
            <p className="footer_title">{footerLocation}</p>
            <address>
              Via Torino, 75
              <br />
              12040 - Vezza d'Alba
              <br />
              Cuneo
              <br />
              Italia
              <br />
            </address>
          </div>
        </div>
        <div className="footer_work_in_progress">
          <span>{workInProgress}</span>
        </div>
      </div>
    </>
  );
}

export default App;
