import React from "react";

import "./style.css";

interface ButtonProps {
  title: string;
  onClick: () => void;
  theme: "light" | "dark";
  fixedSize?: number;
  icon?: boolean;
}

const Button = (props: ButtonProps): JSX.Element => {
  const [isHover, setIsHover] = React.useState<boolean>(false);

  const colors = React.useMemo(() => {
    let backgroundColor;
    let textColor;
    let borderColor;

    if (isHover) {
      backgroundColor = "#037fa3";
      borderColor = props.theme === "dark" ? "#231f20" : "white";
      textColor = "white";
    } else {
      borderColor = "";
      if (props.theme === "dark") {
        backgroundColor = "#231f20";
        textColor = "white";
      } else {
        backgroundColor = "white";
        textColor = "#231f20";
      }
    }

    return {
      background: backgroundColor,
      text: textColor,
      border: borderColor,
    };
  }, [props.theme, isHover]);

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="main_container"
      style={{
        backgroundColor: colors.background,
        color: colors.text,
        borderColor: colors.border,
        maxWidth: props.fixedSize ? `${props.fixedSize}rem` : undefined,
        justifyContent: props.icon ? 'space-between' : 'center',
        fontWeight: 700,
      }}
      onClick={props.onClick}
    >
      {props.title}
      {props.icon ? (
        <span className="material-symbols-outlined btn_icon">
          arrow_outward
        </span>
      ) : null}
    </div>
  );
};

export default Button;
